<template>
  <el-dialog
    id="SelectOrgAliasDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="80%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container v-loading="loading">
      <el-header class="header3" height="48"
        ><el-row type="flex" justify="start">
          <div>
            <el-input
              :placeholder="$t('Пошук')"
              style="width:308px;"
              prefix-icon="el-icon-search"
              clearable
              v-model="filterString"
              ref="filter"
              @keydown.esc.native="filterString = ''"
            />
          </div> </el-row
      ></el-header>
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="selectTemplateTable"
          id="selectTemplateTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="single"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :treeData="true"
          :rowData="orgsTree"
          :gridOptions="gridOptions"
          :getDataPath="getDataPath"
          :autoGroupColumnDef="autoGroupColumnDef"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @gridReady="onGridReady"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedOrg">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="onCancel" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import { _ } from "vue-underscore";
  import { bus } from "@/main";
  import { AgGridVue } from "@ag-grid-community/vue";
  import { AllModules } from "@ag-grid-enterprise/all-modules";

  function parseError(error) {
    if (typeof error == "string") {
      return error;
    } else if (Array.isArray(error)) {
      return error.join("; ");
    } else return error;
  }

  export default {
    name: "SelectOrgAlias",
    props: ["busEvent"],
    components: { AgGridVue },
    data() {
      return {
        loading: false,
        empty: [],
        filterString: "",
        modules: AllModules,
        dialogShow: false,
        dialogHeight: 0,
        resizeObserver: null,
        title: $tt("Пошук"),
        target: null,
        model: {},
        initModel: {},
        selectedOrg: null,
        waitForAnswer: false,
        keyUpTimer: null,
        filterHasChanged: false,
        gridApi: null,
        gridColumnApi: null,
        excludeForSelect: [],
        operation: "",
        axiomaId: null,
        noRowsToShowTemplate: `<div><img src="${require("../../../assets/icons/no_data.svg")}"></div>`,
        gridOptions: {
          suppressCellSelection: true,
          getRowNodeId: function(data) {
            return data.axiomaId;
          },
          immutableData: true,
          rowHeight: 32,
          floatingFiltersHeight: 32,
        },
        getDataPath: (data) => {
          return data.path;
        },
        defaultColDef: {
          suppressNavigable: true,
          cellClass: "no-border",
          resizable: true,
          sortable: true,
          tooltipValueGetter: function(params) {
            return params.value;
          },
          floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
          suppressMenu: false,
          filter: "agTextColumnFilter",
          floatingFilterComponentParams: { suppressFilterButton: true },
        },
        sideBar: {
          toolPanels: ["columns", "filters"],
          position: "left",
          hiddenByDefault: false,
        },
        autoGroupColumnDef: {
          headerName: $tt("Назва"),
          headerTooltip: $tt("Назва"),
          sort: "asc",
          field: "name",
          cellRendererParams: {
            suppressCount: true,
            checkbox: false,
          },
          comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
            if (!nodeA || !nodeA.data) return 0;
            if (!nodeB || !nodeB.data) return 0;
            if ((nodeA.allChildrenCount == 0 || nodeA.allChildrenCount == null) && nodeB.allChildrenCount > 0) return 1 * (isInverted ? -1 : 1);
            if ((nodeB.allChildrenCount == 0 || nodeB.allChildrenCount == null) && nodeA.allChildrenCount > 0) return -1 * (isInverted ? -1 : 1);
            return valueA > valueB ? 1 : -1;
          },
        },

        columns: [
          {
            headerName: $tt("Адреса"),
            field: "address",
            flex: 1,
          },
          {
            headerName: "AxiomaID",
            field: "axiomaId",
            width: "130px",
            type: "rightAligned",
          },
          {
            headerName: $tt("ЄДРПОУ"),
            field: "okpo",
            width: "130px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Юридична особа"),
            field: "isEntity",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
            },
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.status ? $tt("Активний") : "-";
            },
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      };
    },
    created() {
      bus.$on(this.busEvent + ".show", (data) => {
        this.loading = true;
        this.title = (data && data.title) || vm.title;
        this.filterString = "";
        this.axiomaId = data.axiomaId;
        this.dialogShow = true;
      });
      bus.$on(this.busEvent + ".hide", () => {
        this.dialogShow = false;
        this.resizeObserver = null;
      });
    },
    computed: {
      orgsTree() {
        return this.$store.getters["orgs/orgsAndAliases"](this.filterString);
      },
    },
    watch: {
      dialogHeight(value) {
        var containerHeight = value - 72 - 48 - 16 - 40;
        this.$refs.flex_container.style.height = `${containerHeight}px`;
      },
    },
    methods: {
      onGridReady() {
        this.gridColumnApi = this.gridOptions.columnApi;
        var state = this.$store.getters["main/userSettings"]("SelectOrgAliasGridColumnState");
        if (state) this.gridColumnApi.setColumnState(state);
      },
      observeHeight() {
        var that = this;
        if (!this.resizeObserver) {
          this.resizeObserver = new ResizeObserver(function() {
            that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
          });
          this.resizeObserver.observe(this.$el.children[0]);
        }
      },
      unobserveHeight() {
        this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
        this.resizeObserver = null;
        this.dialogHeight = 0;
      },
      onCommit() {
        if (!this.selectedOrg) {
          this.$message.success($tt("Оберіть корпорацію!"));
          return;
        } else if (this.selectedOrg && this.selectedOrg.axiomaId == this.axiomaId) {
          this.$message.success($tt("Не можна обирати ту ж саму корпорацію!"));
          return;
        } else {
          this.$emit("select-commit", { axiomaId: this.selectedOrg ? this.selectedOrg.axiomaId : null });
          this.dialogShow = false;
        }
      },
      onCancel() {
        this.$emit("select-cancel");
        this.dialogShow = false;
      },
      onOpen() {
        this.observeHeight();
      },
      onOpened() {
        this.loading = false;
        this.gridOptions.api.deselectAll();
        if (this.templateId) {
          var node = this.gridOptions.api.getRowNode(this.templateId);
          if (node) node.setSelected(true);
        }
      },
      onClose() {
        this.unobserveHeight();
      },
      onGridSelectionChanged() {
        var rows = this.gridOptions.api.getSelectedRows();
        if (rows && rows.length > 0) {
          this.selectedOrg = rows[0];
        } else {
          this.selectedOrg = null;
        }
      },
      onGridColumnsChange() {
        var that = this;
        if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
        this.changeColumnsTimer = setTimeout(() => {
          var state = that.gridColumnApi.getColumnState();
          clearTimeout(that.changeColumnsTimer);
          that.$store.dispatch("main/updateUserSettings", { SelectOrgAliasGridColumnState: state });
        }, 500);
      },
      gridLocale(key, defaultValue) {
        var localized = $tt("grid." + key);
        return localized ? localized : defaultValue;
      },
    },
  };
</script>

<style lang="scss" scope>
  #SelectOrgAliasDialog .header2 {
    background: #fff;
    line-height: 32px;
    border-bottom: 1px solid #ccc !important;
    padding: 0 6px !important;
    align-items: center;
    margin-left: -16px;
    margin-right: -16px;
  }

  #SelectOrgAliasDialog.my-dialog .el-dialog--center .el-dialog__body {
    padding: 4px 16px 0 16px !important;
  }

  .flex_container {
    width: 100%;
  }
</style>
